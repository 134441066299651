<template>
  <base-section id="theme-features">
    <base-section-heading title="Productos completamente naturales y artesanos">
    </base-section-heading>
    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
           {{ feature.desc }}
          </base-avatar-card>
        </v-col>
        <base-btn
          :to="{ name: 'Productos' }"
          class="pa-1"
          elevation='2'
        >
          ¡Descubre todos nuestros productos!
        </base-btn>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          color: 'primary',
          dark: true,
          title: 'Pudin',
          icon: 'mdi-star',
          desc: 'Nuestra sección más vendida. Contando con el pastel de pescado roca como plato estrella cuenta con lo necesario para satisfacer los paladares más exigentes. Además, disponemos de otros dos diferentes pasteles como son el centollo y merluza con pimientos, exquisito.',
        },
        {
          title: 'Txangurro',
          icon: 'mdi-fish',
          desc: 'Este preparado de carne de centollo y buey de mar cuenta con una deliciosa elaboración para aquellos amantes de los rellenos. Entre los más recomendados, se encuentran pimientos del piquillo, berenjenas y calabacines.',
        },
        {
          title: 'Especialidades Gourmet',
          icon: 'mdi-silverware-variant',
          desc: 'Productos elaborados con la mayor delicadeza, especializades dirigidas a aquellas que buscan otra versión de los platos tradicionales. Una manera de ofrecer al cliente un producto gourmet de una manera fácil y rápida.',
        },
        {
          title: 'Pinchos',
          icon: 'mdi-silverware-fork',
          desc: 'El producto especial para entrantes y picoteo. Sobre una tosta de pan y en una cantidad no abundante es la forma correcta de disfrutar de esta delicia.',
        },
        {
          title: 'Conservas',
          icon: 'mdi-fridge',
          desc: 'De nuestras delicias más clásicas ofrecemos un surtido de conservas más cómodo y para disfrutar en el momento.',
        },
      ],
    }),
  }
</script>
